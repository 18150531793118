// Polyfill
import './utils/polyfillRouter'

// Set logging prototype as soon as possible
import Vue from 'vue'
import VueMask from 'v-mask'
import { logger } from '@/utils/logger'
Vue.prototype.$logger = logger // we add logger to the prototype so that we can access it in aven_shared
Vue.prototype.$logEvent = window.logEvent
import VueCookies from 'vue-cookies'
import App from './App.vue'
import NetworkUnavailable from './NetworkUnavailable.vue'
import router from './routes/router'
import './utils/validation'
import './utils/exception-handler'
import { i18n } from './utils/i18n'
import { initializeSessionRecording } from './services/marketing'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { ValidationProvider } from 'vee-validate'

declare global {
    interface Window {
        logEvent: any
        Plaid: any
        previousPath: string
        zE: any
        MSStream: any
    }
}

// we need to register this component globally so that we can use it in aven_shared templates
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

Vue.use(VueCookies)

Vue.use(VueMask)

export const currentContextForLogging = {}

Vue.mixin({
    data() {
        return {
            pageRoute: this.$route.path,
        }
    },
    created() {
        try {
            if (this.name || this.$vnode?.tag || this.uid) {
                currentContextForLogging[this.name || this.$vnode?.tag || this.uid] = this
            }
        } catch (e) {
            logger.fatal(`error adding logging context of component ${this?.name || this?.$vnode?.tag || this?.uid}`, e)
        }
    },
})

let isMounted = false
const initVue = () => {
    if (isMounted) {
        return
    }

    logger.log('Enabling vue...')
    new Vue({
        router,
        i18n,
        render: (h) => h(App),
    }).$mount('#app')
    isMounted = true

    initializeSessionRecording()

    // my god batman, are we logging an event once every second???
    // no robin, it's 10 seconds now. but we won't save it to the db like you think.
    setInterval(() => {
        window.logEvent('still_here')
    }, 10000)
}

const initVueNetworkUnavailable = () => {
    if (isMounted) {
        return
    }

    // Don't bother sending to the backend, unlikely to succeed + generates tons of console errors
    logger.setNetworkLogging(false)
    logger.log('Enabling vue (network unavailable)...')
    new Vue({
        i18n,
        render: (h) => h(NetworkUnavailable),
    }).$mount('#app')

    isMounted = true
}

const getMetadata = () => {
    return {
        resolution: window.innerWidth + 'x' + window.innerHeight,
        cookies: document.cookie,
        // Pass raw query string to allow backend to parse however it likes
        queryParams: window.location.search,
        path: window.location.pathname,
        avenProperty: 'NOTARY',
    }
}

const fireSessionIdRequest = () => {
    const sessionReadyEvent = new Event('sessionIdReady')

    const req = new XMLHttpRequest()
    req.onreadystatechange = function () {
        // When request ready + successful
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            const resp = JSON.parse(this.responseText)
            console.log('Setting sessionId: ' + resp.payload.sessionId)
            appSessionStorage.setItem(sessionStorageKey.sessionAccessJWT, resp.payload.jwt.sessionAccessJWT)
            appSessionStorage.setItem(sessionStorageKey.sessionId, resp.payload.sessionId)
            appSessionStorage.setItem(sessionStorageKey.experimentName, resp.payload.experimentName)
            window.dispatchEvent(sessionReadyEvent)
        }
        // We abort the request in abortCallIfSessionIdNotCompleteWithinXSeconds() if it takes too long.
        else if (this.readyState === XMLHttpRequest.DONE && this.status === 0) {
            logger.error(`getSessionId request was aborted (usually because the request took too long)`)
        } else if (this.readyState === XMLHttpRequest.DONE) {
            throw new Error('getSessionId call failed with status ' + this.status)
        }
    }
    req.open('POST', `${process.env.VUE_APP_API_BASE_URL}/ana/session`)
    req.setRequestHeader('Content-Type', 'application/json')
    console.log('Requesting sessionId + experimentName, also sending metadata')
    const body = JSON.stringify(getMetadata())
    setTimeout(() => {
        req.send(body)
    }, 100)

    return req
}

const abortCallIfSessionIdNotCompleteWithinXSeconds = (xmlHttpRequest, timeout, callback) => {
    setTimeout(function () {
        if (!appSessionStorage.getItem(sessionStorageKey.sessionId)) {
            console.log('Took too long to load, aborting')
            xmlHttpRequest.abort()
            if (callback) {
                callback()
            }
        }
    }, timeout)
}

const isSessionIdPresent = () => {
    return (
        appSessionStorage.getItem(sessionStorageKey.sessionId) &&
        appSessionStorage.getItem(sessionStorageKey.sessionId) !== 'undefined' &&
        appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT) &&
        appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT) !== 'undefined'
    )
}

const init = () => {
    if (isSessionIdPresent()) {
        console.log('sessionId and sessionAccessJWT already set')
        initVue()
        return
    }
    // @ts-ignore
    console.log('Waiting for sessionIdReady / networkUnavailable event...')

    window.addEventListener(
        'sessionIdReady',
        function () {
            logger.log('Received sessionIdReady event')
            initVue()
        },
        false
    )

    window.addEventListener(
        'networkUnavailable',
        function () {
            logger.log('Received networkUnavailable event')
            initVueNetworkUnavailable()
        },
        false
    )

    const queryParams = new URLSearchParams(window.location.search)

    const sessionIdReq = fireSessionIdRequest()
    abortCallIfSessionIdNotCompleteWithinXSeconds(sessionIdReq, 5000, function () {
        const timesReloaded = parseInt(queryParams.get('reloaded')) || 0

        if (timesReloaded < 3) {
            console.log('Force reloading page because sessionId call failed, page already reloaded ' + timesReloaded + ' times')

            queryParams.set('reloaded', `${timesReloaded + 1}`)
            window.location.search = queryParams.toString()
        } else {
            console.log('Dispatching networkUnavailable event')
            const networkUnavailableEvent = new Event('networkUnavailable')
            window.dispatchEvent(networkUnavailableEvent)
        }
    })
}

// Actually call init routine.
init()
