import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { getOriginationNextRouteWithCurrentPath } from '@/flow/originationFlow'
import { getNotaryNextRouteWithCurrentPath } from '@/flow/notaryFlow'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { RouteOption } from '@/flow/flowUtility'
import { logger } from '@/utils/logger'
import { getCreditLineIncreaseNextRouteWithCurrentPath } from '@/flow/creditLimitIncreaseFlow'
import { getCliAndAprReductionAndBalanceSweepNextRouteWithCurrentPath } from '@/flow/cliAndAprReductionAndBalanceSweepFlow'

export enum Flows {
    origination = 'origination',
    creditLimitIncrease = 'creditLimitIncrease',
    cliAndAprReductionAndBalanceSweep = 'cliAndAprReductionAndBalanceSweep',
    notarization = 'notarization',
    notaryAdmin = 'notaryAdmin',
}

export let latestPath = 'initializationPath'

export const getNextRoute = (pageInstance: { pageRoute: string }, routeOption?: RouteOption): string => {
    const nextPath = getNextPath(pageInstance.pageRoute, routeOption)
    latestPath = nextPath
    return nextPath
}

export const getNextPath = (currPath: string, routeOption?: RouteOption): string => {
    const currentFlow: Flows = appSessionStorage.getItem(sessionStorageKey.currentFlow) as Flows

    if (currentFlow === Flows.origination) {
        const nextPath = getOriginationNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    if (currentFlow === Flows.notaryAdmin) {
        const nextPath = getNotaryNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    if (currentFlow === Flows.creditLimitIncrease) {
        const nextPath = getCreditLineIncreaseNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    if (currentFlow === Flows.cliAndAprReductionAndBalanceSweep) {
        const nextPath = getCliAndAprReductionAndBalanceSweepNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    logger.info('flow not found, could not determine next page')
    return sharedPagePaths.PAGE_NOT_FOUND
}
